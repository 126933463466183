<template>
<selectType/>
</template>

<script>
import selectType from '@/components/selectType.vue'
export default {
    components:{
        selectType
    },
    data() {
        return {
           

            }
        }
 
}
</script>
